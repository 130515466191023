<template>
    <div class="dF aC">
        <div class="container">
            <div class="text-center">
                <div style="color: #858586">CREATE NEW PROJECT</div>
                <div class="mt-2" style="font-size: 27px; color: #40454c">
                    Choose a project type
                </div>
            </div>
            <div class="grid-display mt-5">
                <div
                    class="type-card"
                    v-for="(type, typeI) in projectTypes"
                    :key="type.type + typeI"
                >
                    <div
                        class="text-center"
                        style="color: #000; font-size: 20px;"
                    >
                        {{ type.name }}
                    </div>
                    <div
                        class="mt-3 text-center"
                        style="color: #40454c; min-height: 115px"
                    >
                        {{ type.description }}
                    </div>
                    <div class="dF jC w-full">
                        <a-button
                            class="mt-4"
                            type="primary"
                            @click="selectType(type.type)"
                            >{{ `CREATE NEW ${type.name}` }}</a-button
                        >
                    </div>
                    <div class="mt-4 text-center">
                        <a
                            href="https://bildhive.com/contact-sales"
                            target="”_blank”"
                            style="color: var(--orange); font-size: 16px"
                            >Contact Sales</a
                        >
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center">
                <div style="color: #000; font-size: 16px">
                    A credit card is required to set up a project
                </div>
                <div style="color: #40454c" class="mt-3">
                    Please choose a project type to get started
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            projectTypes: [
                {
                    name: "COMMUNITY OR CONDO PROJECT",
                    type: "solo",
                    description:
                        "Select this subscription if you want to use Bildhive for a standalone Low Rise Community or a Condominium Project. You will also need to select this subscription if you are participating in a group or corporate project.",
                },
                {
                    name: "GROUP PROJECT",
                    type: "group",
                    description:
                        "A Group Project subscription is ideal for Brokerages and or Marketing Agencies who are managing the sales and marketing efforts for a group of builders who are bringing a community development to market in unity.",
                },
                {
                    name: "CORPORATE PROJECT",
                    type: "corporate",
                    description:
                        "A Corporate Project subscription is ideal if you want to consolidate all your community projects under one corporate umbrella.",
                },
            ],
        };
    },
    methods: {
        selectType(type) {
            this.$emit("selectType", type);
        },
    },
};
</script>

<style scoped>
.grid-display {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.75rem;
}
.type-card {
    border: 2px solid #d0c9d6;
    border-radius: 4px;
    padding: 30px 20px;
}
.type-card:hover {
    border-color: var(--orange);
}

.box-center {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
	margin-bottom: 100px;
}
</style>
